.container h3 {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    color: var(--color-black);
    text-align: center;
}
.container p {
    max-width: 330px;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--color-black);
    text-align: center;
    margin: 0 auto 36px auto;
}
.masterTemplatesList {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 28px;
    max-height: 390px;
    overflow-y: scroll;
    margin-bottom: 36px;
}
/* width */
.masterTemplatesList::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.masterTemplatesList:hover::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.masterTemplatesList:hover::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
}
/* Track */
.masterTemplatesList::-webkit-scrollbar-track {
    background-color: transparent;
}
/* Handle */
.masterTemplatesList::-webkit-scrollbar-thumb {
    background-color: var(--color-green);
    border-radius: 16px;
}
.masterTemplatesList::-webkit-scrollbar-button {
    display: none;
}
.buttons {
    display: flex;
    justify-content: space-between;
}