.container {
    display: flex;
    flex-direction: column;
    gap: 36px;
}
.container  h3 {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    color: var(--color-black);
    text-align: center;
}
.selectTemplateTypeButtons {
    display: flex;
    gap: 80px;
}
.line {
    height: 130px;
    width: 1px;
    background: var(--select-border-color);
}
.selectTemplateTypeButtons button {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    background: transparent;
    border: 1px solid transparent;
    text-decoration: none;
    cursor: pointer;
}
.selectTemplateTypeButtons button h6 {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px;
    color: var(--color-black);
}
.selectTemplateTypeButtons button p {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--color-black);
    text-align: center;
}
.icon {
    width: 36px;
    height: 36px;
}
.icon svg {
    width: 36px;
    height: 36px;
}
.icon svg path {
    fill: var(--color-green);
    transition: .3s;
}
.selectTemplateTypeButtons button:hover .icon svg path {
    fill: var(--color-blue);
}
.buttons {
    display: flex;
    justify-content: center;
}