.container {
    padding: 30px 0 0 32px;
    letter-spacing: .18px;
    font-family: 'ProximaNova-Regular', sans-serif;
}
.consentCategoryContainer {
    display: flex;
    flex-direction: column;
}
.consentCategoryColumns {
    display: flex;
    justify-content: space-between;
    gap: 18px;
}
.templatesEmailFolderPageContainer,
.templatesLibraryPageContainer {
    display: flex;
    gap: 16px;
    min-height: calc(100vh - 95px) !important;
}
.templatesEmailFolderPageContainer .leftSide,
.templatesLibraryPageContainer .leftSide,
.templatesEmailFolderPageContainer .rightSide,
.templatesLibraryPageContainer .rightSide {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: calc(100vh - 95px) !important;
}
.templatesEmailFolderPageContainer .rightSide,
.templatesLibraryPageContainer .rightSide {
    width: 100%;
}
.uploadImageModal {
    max-width: 530px;
    padding: 32px 32px 46px 32px;
}
.uploadProgressModal {
    max-width: 530px;
    padding: 40px 32px;
}
.templatesLibraryPageSelectContentLayout {
    height: calc(100vh - 95px);
    min-height: 816px;
}
.templatesPreviewPageCard {
    width: 100%;
}
.previewContainer .templateEmailMasterPreview {
    width: 680px;
    border: 0;
}
.previewContainer.htmlMasterNotExist {
    min-height: 700px;
    padding: 0;
}
.previewContainer .defaultPreviewPictureContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 203px);
    min-height: 700px;
    background: var(--color-white);
}
.defaultPreviewPictureContainer .defaultPreviewPictureWrapper {
    width: 705px;
    height: 395px;
    position: relative;
}
.defaultPreviewPictureWrapper .message {
    position: absolute;
    top: 150px;
    left: 70px;
    font-style: normal;
    color: var(--color-white);
}
.defaultPreviewPictureWrapper .message h6 {
    font-family: 'ProximaNova-Bold', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
}
.defaultPreviewPictureWrapper .message p {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}
.emailSetupContainer {
    height: calc(100vh - 211px);
}
.createEmailTemplateModal {
    min-width: 700px;
    max-width: 780px;
}
@media screen and (max-width: 1440px) {
    .previewContainer {
        flex-wrap: wrap-reverse;
    }
}
