.emailMasterPreviewCardContainer {
    width: 188px;
    height: 178px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--select-border-color);
    border-radius: 5px;
    padding: 12px;
    transition: .3s;
}
.emailMasterPreviewCardContainer.selected {
    border: 2px solid var(--color-green);
}
.emailMasterPreviewCard,
.defaultMasterPreview {
    width: 100%;
    height: 100%;
    max-width: 175px;
    background-image: url('../../../../assets/img/DefaultTemplate.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}