.container {
    display: flex;
    flex-direction: column;
    gap: var(--size-lg);
}
.values {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.value {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 var(--size-md);
    cursor: pointer;
}
.value .text {
    color: var(--Slate-300);
    transition: .3s ease;
}
.icon {
    width: 20px;
    height: 20px;
}
.icon svg path {
    transition: .3s ease;
}
.value.selected .text {
    color: var(--Slate-950);
}
.value .icon svg path {
    stroke: var(--Slate-300);
}
.value.selected .icon svg path {
    stroke: var(--Slate-950);
}
@media screen and (max-width: 540px) {
    .values {
        display: none;
    }
}