.container {
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    min-height: 800px;
    width: 220px;
    min-width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-black-blue);
}
.container:before {
    position: absolute;
    top: -10px;
    left: 10px;
    height: 220px;
    width: 200px;
    background: radial-gradient(50% 50% at 50% 50%, #00C2A6 24.48%, rgba(255, 255, 255, 0) 100%);
    opacity: .1;
    transform: rotate(90deg);
    content: '';
}
.container:after {
    position: absolute;
    top: 200px;
    left: 0;
    height: 500px;
    width: inherit;
    background: radial-gradient(50% 50% at 50% 50%, #00C2A6 24.48%, rgba(255, 255, 255, 0) 100%);
    opacity: .13;
    content: '';
    z-index: 0;
}
.container .top {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 5px 5px 5px 25px;
}
.container .logo {
    width: 143px;
    height: 33px;
    background-image: url('../../assets/img/Logo.svg');
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    margin: 18px auto 35px 0;
}
.container a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
    height: 24px;
    text-decoration: none;
    color: var(--color-white);
    transition: .2s;
    z-index: 1;
    cursor: pointer;
}
.container a.active:before {
    position: absolute;
    top: 50%;
    left: -25px;
    display: block;
    width: 5px;
    height: 30px;
    background: var(--color-green);
    transform: translateY(-50%);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    content: ''
}
.container a p {
    font-family: "ProximaNova-Semibold", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}
.container a .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transition: .2s;
    fill: var(--color-white);
}
.container a:hover,
.container .active {
    color: var(--color-green);
}
.container a:hover .icon,
.container .active .icon {
    fill: var(--color-green);
}
.container .bottom {
    padding-bottom: 20px;
}
.container .bottomLink {
    height: 100%;
    max-height: 74px;
    border-top: 1px solid var(--color-white);
    padding: 24px 8px 24px 25px;
}
.container .bottomLink:last-child {
    border-bottom: 1px solid var(--color-white);
}
@media screen and (max-width: 1365px) {
    .container {
        width: 74px;
        min-width: 74px;
        z-index: 1;
    }
    .container:before {
        display: none;
    }
    .container:after {
        display: none;
    }
    .container .top {
        padding-top: 140px;
    }
    .container .logo {
        width: 100%;
        position: absolute;
        top: 10px;
        left: 0;
        background-image: url('../../assets/img/LogoSmall.svg');
    }
    .container a {
        position: relative;
    }
    .container a:hover,
    .container .active {
        color: var(--color-white);
    }
    .container a p {
        position: absolute;
        top: -5px;
        left: calc(100% + 20px);
        background: var(--color-black-blue);
        padding: 10px 6px;
        border-radius: 4px;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        transition: .3s;
    }
    .container a p:before {
        display: block;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 12px;
        left: -6px;
        background: var(--color-black-blue);
        transform: rotate(45deg);
        z-index: -1;
        content: '';
    }
    .container a:hover p {
        opacity: 1;
        z-index: 1;
        visibility: visible;
    }
}