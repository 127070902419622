.container {
    display: flex;
    align-items: center;
    gap: var(--size-xl);
    justify-content: space-between;
}
.column {
    display: flex;
    flex-direction: column;
    gap: var(--size-sm);
}
.column.directionEnd {
    align-items: flex-end;
}
.price {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}
@media screen and (max-width: 540px) {
    .container {
        flex-direction: column;
        align-items: flex-start;
    }
    .column.directionEnd {
        align-items: flex-start;
    }
    .customers {
        flex-direction: column;
        align-items: flex-start !important;
    }
}